'use client';

import Script from 'next/script';

export default function LemonAffiliateScript() {
  return (
    <Script
      src="https://lmsqueezy.com/affiliate.js"
      defer
      async
      strategy="lazyOnload"
      onLoad={() => { eval('window.lemonSqueezyAffiliateConfig = { store: "brightthemes" };') }}
    />
  );
}
