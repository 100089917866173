'use client';

import Script from 'next/script';
import { usePathname } from 'next/navigation'
import { useEffect } from 'react';

export default function LemonScript() {
  const pathname = usePathname()

  useEffect(() => {
    if (eval('typeof ' + 'LemonSqueezy') == 'object') {
      eval('LemonSqueezy').Refresh();
    }
  
    // Logsnag tracking 
    const tracking = ({channel,product,notify}:{channel: string, product: string, notify: boolean}) => {
      const logsnag = eval('new window.LogSnag({ token: "75f88ac7fb475166c57f6e2ef409a608", project: "bright-themes"})');
      logsnag.track({
        channel: channel,
        event: "Possible purchase",
        description: product,
        icon: "👀",
        tags: {
          path: pathname,
        },
        notify: notify
      })
    }

    const lemonButtons = document.querySelectorAll<HTMLElement>('.lemonsqueezy-button');
    lemonButtons.forEach(button => {
      button.onclick = () => {
        const productName = button.getAttribute('data-product') 
        const product = productName ? productName : 'test'
        if (process.env.NODE_ENV === "development") {
          // tracking({channel: 'test', product: product, notify: false});
        } else {
          tracking({channel: 'acquisition', product: product, notify: false});
        }
        // const gtagDataLayer = eval('dataLayer');
        // gtagDataLayer.push({'event': 'add_to_cart'});
      }
    })
  }, [pathname]);

  return (
    <Script
      src="https://app.lemonsqueezy.com/js/lemon.js"
      defer
      async
      strategy="lazyOnload"
      onLoad={() => { eval('createLemonSqueezy()') }}
      onReady={() => { eval('LemonSqueezy.Refresh()') }}
    />
  );
}
