import { Dispatch, SetStateAction } from 'react';

import Link from 'next/link';
import Icon from '#/components/icon';

export default function NavMobile({
  setShowMobileMenu,
  showMobileMenu,
}: {
  setShowMobileMenu: Dispatch<SetStateAction<boolean>>;
  showMobileMenu: boolean;
}) {
  const links = [
    { name: 'Home', href: '/' },
    { name: 'Themes', href: '/themes/' },
    { name: 'Bundle', href: '/bundle/' },
    { name: 'Services', href: '/services/' },
    { name: 'Showcase', href: '/showcase/' },
    { name: 'Blog', href: '/blog/' },
    { name: 'Docs', href: '/docs/' },
    // { name: 'Changelog', href: '/changelog/' },
    { name: 'Contact', href: '/contact/' },
  ];
  return (
    <div className="absolute right-0 top-0 z-10 mr-3 mt-3 flex w-screen max-w-[calc(100%_-_2rem)] justify-end md:hidden">
      <div className="flex w-screen max-w-sm flex-auto flex-col gap-y-1 overflow-hidden rounded-xl bg-accent-bg-1 leading-6 shadow-lg ring-1 ring-accent-brd">
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="m-3 inline-flex items-center self-end rounded-full p-2 text-sm text-accent-txt-1 ring-2 ring-accent-brd hover:bg-accent-bg-2 focus:bg-accent-bg-2 md:hidden"
          aria-controls="navbar-default"
          aria-expanded="false"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          <span className="sr-only">Close main menu</span>
          <Icon name="x" className="h-4 w-4" strokeWidth="2.5" />
        </button>
        <ul className="divide-y border-t">
          {links.map((item) => (
            <li key={item.name}>
              <Link
                href={item.href}
                className="flex px-3 py-2 hover:bg-accent-bg-2"
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
