import Link from 'next/link';
import Icon from '#/components/icon';

export default function ButtonArrow({
  link,
  text,
  color,
  size,
  linkClass,
  target,
  rel,
  external,
  product,
  children
}: {
  link: string;
  text: string;
  color?: string;
  size?: string;
  linkClass?: string;
  target?: string;
  rel?: string;
  external?: boolean;
  product?: string;
  children?: React.ReactNode;
}) {
  let colorClass = ' bg-accent-txt-1 text-accent-bg-1';

  switch (color) {
    case 'brand':
      colorClass = ' bg-brand text-brand-contrast border border-opac shadow-in-1px';
      break;
    case 'secondary':
      colorClass = ' bg-secondary text-secondary-contrast border border-opac shadow-in-1px';
      break;
    case 'contrast':
      colorClass = ' bg-accent-txt-1 text-accent-bg-1';
      break;
    case 'transparent':
      colorClass = ' text-accent-txt-1';
      break;
    case 'bordered':
      colorClass =
        ' text-accent-txt-1 border border-accent-brd hover:bg-accent-bg-2';
      break;
    default:
      colorClass = ' bg-accent-txt-1 text-accent-bg-1';
  }

  let sizeClass = '';

  switch (size) {
    case 'md':
      sizeClass = ' md:text-base md:py-1.5 md:px-5 md:pr-3';
      break;
    case 'lg':
      sizeClass =
        ' md:text-base md:py-1.5 md:px-5 md:pr-3 lg:text-lg lg:py-2 lg:px-6 lg:pr-4';
      break;
    default:
      sizeClass = '';
  }

  return (
    <>
      {external
        ? <a
            target={target}
            rel={rel}
            href={link}
            data-product={product}
            className={`group flex items-center rounded-full px-4 py-1.5 pr-2.5 text-sm font-normal${colorClass}${sizeClass} ${linkClass}`}
          >
            {children}
            <span className="">{text}</span>
            <i className="relative ml-1 h-4 w-4">
              <Icon
                name="minus"
                className="absolute h-4 opacity-0 transition-all group-hover:opacity-100 group-focus:opacity-100"
                strokeWidth="2.5"
              />
              <Icon
                name="chevron-right"
                className="absolute left-[-1px] h-4 w-4 transition-all group-hover:left-[1px] group-focus:left-[1px]"
              />
            </i>
          </a>
        : <Link
            target={target}
            rel={rel}
            href={link}
            data-product={product}
            className={`group flex items-center rounded-full px-4 py-1.5 pr-2.5 text-sm font-normal${colorClass}${sizeClass} ${linkClass}`}
          >
            {children}
            <span className="">{text}</span>
            <i className="relative ml-1 h-4 w-4">
              <Icon
                name="minus"
                className="absolute h-4 opacity-0 transition-all group-hover:opacity-100 group-focus:opacity-100"
                strokeWidth="2.5"
              />
              <Icon
                name="chevron-right"
                className="absolute left-[-1px] h-4 w-4 transition-all group-hover:left-[1px] group-focus:left-[1px]"
              />
            </i>
          </Link>
      }
    </>
   
  );
}
