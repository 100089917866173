'use client';

import Script from 'next/script';
import { usePathname } from 'next/navigation'

export default function SubscribeForm({ id, formClass }: { id?: string, formClass?: string }) {
  const pathname = usePathname()

  return (
    <div className={`subscribe-form relative flex w-full max-w-sm overflow-hidden h-auto ${formClass}`}>
      <Script
        strategy="lazyOnload"
        src="https://cdn.jsdelivr.net/ghost/signup-form@~0.1/umd/signup-form.min.js"
        defer
        async
        id={id}
        data-label-1="Subscriber"
        data-label-2={`page:${pathname}`} 
        data-button-color="#fc0" 
        data-button-text-color="#000" 
        data-site="https://news.brightthemes.com/"
        onReady={() => { 
          const ghostForm = document.querySelectorAll('.gh-signup-root')
          document.querySelector('.subscribe-form')?.appendChild(ghostForm[0])
        }}
      />
    </div>

    // <form
    //   className={`flex h-12 w-full max-w-sm overflow-hidden rounded-full border border-accent-brd p-1 ${formClass}`}
    //   name="subscribe"
    //   method="POST"
    //   action="/success"
    //   data-netlify="true"
    //   data-netlify-honeypot="bot-field"
    // >
    //   <input
    //     type="hidden"
    //     name="form-name"
    //     value="subscribe"
    //     aria-label="hidden"
    //   />
    //   <input type="hidden" name="bot-field" aria-label="hidden" />
    //   <input
    //     className="focus:ring-none flex-1 appearance-none border-none bg-transparent px-2 py-1 pl-4 outline-none focus:border-none focus:outline-none"
    //     type="email"
    //     name="email"
    //     // id="subscribe"
    //     aria-label="Email"
    //     placeholder="Your Email"
    //     required
    //   />
    //   <input
    //     name="subject"
    //     type="hidden"
    //     value="[SUBSCRIBE] brightthemes.com"
    //     aria-label="Subject"
    //     readOnly
    //   />

    //   <button
    //     className="rounded-full bg-secondary px-4 py-1 text-sm font-normal text-secondary-contrast md:px-6"
    //     type="submit"
    //     aria-label="Subscribe"
    //   >
    //     Subscribe
    //   </button>
    // </form>
  );
}
