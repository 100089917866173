'use client';

import Link from 'next/link';
import Image from 'next/image';
import Icon from '#/components/icon';
import SubscribeForm from './subscribe-form';
import { siteConfig } from '#/config/site';

export default function Footer() {
  const socials = [
    {
      name: 'Twitter',
      href: 'https://twitter.com/brightthemes_',
      icon: 'twitter',
      external: true,
    },
    {
      name: 'GitHub',
      href: 'https://github.com/brightthemes',
      icon: 'github',
      external: true,
    },
    {
      name: 'Dribbble',
      href: 'https://dribbble.com/brightthemes',
      icon: 'dribbble',
      external: true,
    },
    {
      name: 'Instagram',
      href: 'https://instagram.com/bright_themes',
      icon: 'instagram',
      external: true,
    },
    // {
    //   name: 'Facebook',
    //   href: 'https://facebook.com/brightthemes',
    //   icon: 'facebook',
    //   external: true,
    // },
    {
      name: 'RSS',
      href: `/blog/rss/`,
      icon: 'rss',
      external: false,
    },
  ];

  const products = [
    { name: 'Premium Themes', href: '/themes/' },
    { name: 'Pro Bundle', href: '/bundle/' },
    { name: 'Custom Themes', href: '/custom-themes/' },
    { name: 'Free Themes', href: '/free-themes/' },
    { name: 'Landing Pages', href: '/landingpages/' },
    { name: 'Showcase', href: '/showcase/' },
    { name: 'Widgets', href: '/widgets/', tag: 'new' },
    { name: 'Services', href: '/services/', tag: 'new' },
    // { name: 'Components', href: '/components/', tag: ''},
  ];

  const resources = [
    { name: 'Documentation', href: '/docs/' },
    { name: 'Changelog', href: '/changelog/' },
    { name: 'Blog', href: '/blog/' },
    { name: 'FAQ', href: '/faq/' },
    { name: 'Snippets', href: '/snippets/' },
    { name: 'Hosting', href: '/ghostpro-tiers', external: true },
    { name: 'Official Themes', href: '/official-themes' },
  ];

  const about = [
    { name: 'Pricing', href: '/pricing/' },
    { name: 'Categories', href: '/themes/categories/' },
    { name: 'Contact', href: '/contact/' },
    { name: 'Support', href: '/support/' },
    { name: 'Feedback', href: '/feedback/' },
    { name: 'Testimonials', href: '/testimonials/' },
    { name: 'Affiliate', href: '/affiliate/' },
  ];

  const legal = [
    { name: 'Privacy', href: '/privacy/' },
    { name: 'Terms', href: '/terms/' },
    { name: 'Refunds', href: '/terms#refunds' },
  ];

  return (
    <footer className="my-8 bg-accent-bg-1 px-4 text-accent-txt-2">
      <div className="mx-auto max-w-5xl">
        <div className="mb-8 flex flex-col gap-4 border-y border-accent-brd py-8 md:flex-row md:items-center md:justify-between">
          <div>
            <span className="text-base font-normal leading-6 text-accent-txt-1 md:text-lg">
              Subscribe to our newsletter
            </span>
            <p className="mt-2 max-w-md text-sm leading-5">
              Get the latest Ghost CMS news, updates and guides. <br />
              New theme announcements and occasional discounts.
            </p>
          </div>
          <SubscribeForm id="footer-subscribe"/>
        </div>
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <Link href="/" className="flex items-start">
              <Image
                src="/bright-themes.svg"
                className="mr-2"
                width={40}
                height={40}
                alt="Bright Themes Logo"
              />
              <span
                className="self-center whitespace-nowrap text-xl font-normal"
                aria-label="Bright Themes"
              >
                Bright Themes
              </span>
            </Link>
            <p className="mt-4 text-accent-txt-2">
              Practical, simple to use yet powerful Ghost Themes, with
              membership and newsletter support.
            </p>

            <ul className="mt-5 flex space-x-4">
              {socials.map((social) => (
                <li key={social.name}>
                  {social.external ? (
                    <a href={social.href} target="_blank" rel="noreferrer">
                      <span className="sr-only">{social.name}</span>
                      <Icon
                        name={social.icon}
                        className="h-4 w-4 opacity-90 hover:text-brand"
                        strokeWidth="2.5"
                      />
                    </a>
                  ) : (
                    <Link href={social.href}>
                      <span className="sr-only">{social.name}</span>
                      <Icon
                        name={social.icon}
                        className="h-4 w-4 opacity-90 hover:text-brand"
                        strokeWidth="2.5"
                      />
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div className="mt-16 grid grid-cols-2 gap-8 lg:col-span-2 lg:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <span className="block text-sm font-medium leading-6 text-accent-txt-1">
                  Product
                </span>
                <ul role="list" className="mt-4 flex flex-col gap-2">
                  {products.map((product) => (
                    <li key={product.name}>
                      <Link
                        href={product.href}
                        className="text-sm leading-6 hover:text-accent-txt-1"
                      >
                        {product.name}
                        {product.tag && 
                          <span className='inline-block w-2 h-2 bg-error ml-2 rounded-full'></span>
                        }
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <span className="text-sm font-medium leading-6 text-accent-txt-1">
                  Resources
                </span>
                <ul role="list" className="mt-4 flex flex-col gap-2">
                  {resources.map((resource) => (
                    <li key={resource.name}>
                      {resource.external ? (
                        <a
                          href={resource.href}
                          className="text-sm leading-6 hover:text-accent-txt-1"
                        >
                          {resource.name}
                        </a>
                      ) : (
                        <Link
                          href={resource.href}
                          className="text-sm leading-6 hover:text-accent-txt-1"
                        >
                          {resource.name}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <span className="text-sm font-medium leading-6 text-accent-txt-1">
                  About
                </span>
                <ul role="list" className="mt-4 flex flex-col gap-2">
                  {about.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-sm leading-6 hover:text-accent-txt-1"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <span className="text-sm font-medium leading-6 text-accent-txt-1">
                  Legal
                </span>
                <ul role="list" className="mt-4 flex flex-col gap-2">
                  {legal.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-sm leading-6 hover:text-accent-txt-1"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-accent-brd pt-8 md:flex md:items-center md:justify-between">
          <p className="text-sm leading-5 text-accent-txt-2 opacity-90 md:order-1">
            &copy; {new Date().getFullYear()}{' '}
            <Link href="/" className="hover:underline">
              Bright Themes
            </Link>
            . All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
