'use client';

import Script from 'next/script';

export default function AnalyticsScript() {
  return (
    <>
      <Script
        strategy="lazyOnload"
        src="https://www.googletagmanager.com/gtag/js?id=G-JZBX6XQV40"
        defer
        async
      />
      <Script strategy="lazyOnload" id="analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-JZBX6XQV40', {
            page_path: window.location.pathname
          });
        `}
      </Script>
    </>
  );
}
