'use client';

import Script from 'next/script';

export default function LemonScript() {
  return (
    <Script
      src="https://cdn.logsnag.com/js/1.0.0/ls.js"
      defer
      async
      strategy="lazyOnload"
    />
  );
}
