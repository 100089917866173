import Link from 'next/link';
import Icon from '#/components/icon';

export default function NavResources() {
  const resources = [
    {  href: '/docs/', title: 'Documentation', subtitle: 'Detailed documentation for each premium Ghost theme', icon: 'doc' },
    {  href: '/changelog/', title: 'Changelog', subtitle: 'All the information you need about the theme updates', icon: 'changelog' },
    {  href: '/widgets/', title: 'Widgets', subtitle: 'Add extra functionality to your Ghost CMS site with any theme', icon: 'widgets', tag: 'new' },
    {  href: '/snippets/', title: 'Snippets', subtitle: 'Reusable content snippets for Ghost CMS Themes', icon: 'snippets' },
    {  href: '/landingpages/', title: 'Landing Pages', subtitle: 'A collection of custom landing pages for Ghost Themes', icon: 'landing' },
    // {  href: '/faq/', title: 'FAQ', subtitle: 'Answers to the most frequently asked questions', icon: 'faq' },
  ]
  return (
    <ul className="flex w-screen max-w-sm flex-auto flex-col gap-y-1 overflow-hidden rounded-xl bg-accent-bg-1 p-3 text-sm leading-6 shadow-lg ring-1 ring-accent-brd">
      {resources.map(resource => (
        <li key={resource.href}>
          <Link
            href={resource.href}
            className="group relative flex gap-x-3 rounded-lg px-3 py-2 ring-accent-brd hover:bg-accent-bg-2 hover:ring-1"
          >
            <div className="relative mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-accent-bg-2 border border-accent-brd group-hover:bg-accent-bg-1">
              <Icon
                name={resource.icon}
                className="h-6 w-6 text-secondary contrast-110 brightness-90"
                strokeWidth="1.5"
              />
              {resource.tag && <span className="absolute -right-1 -top-1 inline-block size-2.5 bg-error rounded-full"></span>}
            </div>
            <div>
              <span className="font-medium">{resource.title}</span>
              <p className="font-light leading-4 opacity-80">{resource.subtitle}</p>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );
}
