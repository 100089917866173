import { allOfficialThemes, allThemes } from '#/.contentlayer/generated/';
import { allThemeCategories } from '#/.contentlayer/generated/';

// Get Themes
export function getThemes({type,limit, scheduled, featured}: {type?: string, limit?: number, scheduled?: boolean, featured?: boolean}) {

  let themes = scheduled ? allThemes : allThemes.filter((theme) => theme.published === true);
  themes = featured ? allThemes.filter((theme) => theme.featured === true) : themes
  themes = themes.sort(
    (a, b) =>
      new Date(b.published_date || new Date()).valueOf() -
      new Date(a.published_date || new Date()).valueOf(),
  );

  switch (type) {
    case 'all':
      break;
    case 'premium':
      themes = themes.filter((theme) => theme.price > 0);
      break;
    case 'free':
      themes = themes.filter((theme) => theme.price === 0);
      break;
    default:
  }

  if (limit && limit > 0 && limit < themes.length) {
    themes.length = limit;
  }

  return themes;
}

// Get Theme
export function getTheme(slug: string | string[]) {
  const themes = allThemes.filter((theme) => theme.published === true);
  const theme = themes.find((theme) => theme.slug === slug);
  return theme;
}

// Get Themes
export function getRelatedThemes({type,limit,slug}: {type?: string, limit?: number, slug: string;}) {
  let themes = allThemes.filter((theme) => theme.published === true);
  themes = themes.sort(
    (a, b) =>
      new Date(b.published_date || new Date()).valueOf() -
      new Date(a.published_date || new Date()).valueOf(),
  );

  switch (type) {
    case 'all':
      break;
    case 'premium':
      themes = themes.filter((theme) => theme.price > 0);
      break;
    case 'free':
      themes = themes.filter((theme) => theme.price === 0);
      break;
    default:
  }

  themes = themes.filter((theme) => theme.slug !== slug)

  if (limit && limit > 0 && limit < themes.length) {
    themes.length = limit;
  }

  return themes;
}

// Get Themes By Category
export function getThemesByCategory({type,limit,category}: {type?: string, limit?: number, category: string;}) {
  let themes = allThemes.filter((theme) => theme.published === true);
  themes = themes.sort(
    (a, b) =>
      new Date(b.published_date || new Date()).valueOf() -
      new Date(a.published_date || new Date()).valueOf(),
  );

  switch (type) {
    case 'all':
      break;
    case 'premium':
      themes = themes.filter((theme) => theme.price > 0);
      break;
    case 'free':
      themes = themes.filter((theme) => theme.price === 0);
      break;
    default:
  }

  themes = themes.filter((theme) => theme.category?.includes(category))

  if (limit && limit > 0 && limit < themes.length) {
    themes.length = limit;
  }

  return themes;
}

// Get Theme Categories
export function getThemeCategories({limit}:{limit?:number}={}) {
  let categories = allThemeCategories

  categories.map(category => {
    category.counter = getThemesByCategory({category:category.slug}).length;
  })

  categories = categories.sort(
    (a, b) => b.counter - a.counter
  );

  if (limit) {
    categories.length = limit
  }

  return categories;
}

// Get Theme Category
export function getThemeCategory(slug:string) {
  const category = allThemeCategories.find((item) => item.slug === slug);
  return category;
}

// Get Official Themes
export function getOfficialThemes({limit}: {limit?: number}) {

  const themes = allOfficialThemes.sort(
    (a, b) =>
      new Date(b.release_date || new Date()).valueOf() -
      new Date(a.release_date || new Date()).valueOf(),
  );

  if (limit && limit > 0 && limit < themes.length) {
    themes.length = limit;
  }

  return themes;
}